import 'reflect-metadata';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CompanySandbox } from '../facade/company.sandbox';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog as MatDialog } from '@angular/material/dialog';
import { MatSnackBar as MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AppFacade } from '@core/facades/app.facade';
import { CompanyDto } from '@shared/interfaces/company.dto';
import { CompanyIdentityProvider } from '@shared/interfaces/company-identity-provider.dto';
import { initFlowbite } from 'flowbite';

@Component({
    selector: 'my-company-edit',
    styleUrls: ['my-company-edit.component.scss'],
    templateUrl: 'my-company-edit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyEditComponent implements OnInit, AfterViewInit {
    company$: BehaviorSubject<CompanyDto> = new BehaviorSubject(null);

    companyIdentityProviders$: BehaviorSubject<CompanyIdentityProvider[]> = new BehaviorSubject<CompanyIdentityProvider[]>(null);

    subscriptions: Subscription = new Subscription();

    constructor(
        public companySandbox: CompanySandbox,
        public snackBar: MatSnackBar,
        public translate: TranslateService,
        public dialog: MatDialog,
        private readonly appFacade: AppFacade
    ) {}

    ngOnInit(): void {
        this.loadCompany();
        this.loadIdentityProviders();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            initFlowbite();
        });
    }

    loadCompany() {
        this.subscriptions.add(
            this.companySandbox.getCompany().subscribe(company => {
                this.company$.next(company);
            })
        );
    }

    loadIdentityProviders() {
        this.subscriptions.add(
            this.companySandbox.getIdentityProviders().subscribe(identityProviders => {
                this.companyIdentityProviders$.next(identityProviders);
            })
        );
    }

    onUpdateCompany($event: { company: CompanyDto }): void {
        if ($event) {
            const { company } = $event;
            this.appFacade.editCompany(company);
        }
    }

    onCreateCompanyIdentityProvider($event: { companyIdentityProvider: CompanyIdentityProvider }): void {
        if ($event.companyIdentityProvider) {
            this.subscriptions.add(
                this.companySandbox.createIdentityProvider($event.companyIdentityProvider).subscribe(() => {
                    this.loadIdentityProviders();
                    this.snackBar.open(this.translate.instant('my.company.create.identityprovider.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            );
        }
    }

    onUpdateCompanyIdentityProvider($event: { companyIdentityProvider: CompanyIdentityProvider }): void {
        if ($event.companyIdentityProvider) {
            this.subscriptions.add(
                this.companySandbox.updateIdentityProvider($event.companyIdentityProvider).subscribe(() => {
                    this.loadIdentityProviders();
                    this.snackBar.open(this.translate.instant('my.company.update.identityprovider.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            );
        }
    }

    onDeleteCompanyIdentityProvider($event: { companyIdentityProvider: CompanyIdentityProvider }): void {
        if ($event.companyIdentityProvider) {
            this.subscriptions.add(
                this.companySandbox.deleteIdentityProvider($event.companyIdentityProvider).subscribe(() => {
                    this.loadIdentityProviders();
                    this.snackBar.open(this.translate.instant('my.company.delete.identityprovider.success'), undefined, {
                        panelClass: ['success'],
                    });
                })
            );
        }
    }
}
