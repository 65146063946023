<ng-container *ngIf="identityProviders && identityProviders.length > 0">
    <div class="flex gap-1 justify-end mt-2">
        <button class="h-9" mat-raised-button color="primary" (click)="onCreateIdentityProvider()">
            <mat-icon>add</mat-icon>
            {{ 'v2.company.edit.identityproviders.none.found.configure' | translate }}
        </button>
    </div>
    <table mat-table [dataSource]="identityProviders">
        <ng-container matColumnDef="name" class="mat-elevation-z8">
            <th mat-header-cell *matHeaderCellDef>{{ 'v2.company.edit.identityproviders.name' | translate }}</th>
            <td mat-cell *matCellDef="let identityProvider">{{ identityProvider.name }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>{{ 'v2.company.edit.identityproviders.actions.title' | translate }}</th>
            <td class="actions-column" mat-cell *matCellDef="let identityProvider">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="onEditIdentityProvider(identityProvider)">
                        <mat-icon>edit</mat-icon>
                        {{ 'v2.company.edit.identityproviders.actions.edit' | translate }}
                    </button>
                    <button mat-menu-item (click)="onDeleteIdentityProvider(identityProvider)">
                        <mat-icon>delete</mat-icon>
                        {{ 'v2.company.edit.identityproviders.actions.delete' | translate }}
                    </button>
                </mat-menu>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</ng-container>
<div class="no-identity-providers flex justify-center items-center flex-col" *ngIf="!identityProviders || identityProviders.length === 0">
    <h2 class="!mb-2">{{ 'v2.company.edit.identityproviders.none.found.title' | translate }}</h2>
    <img class="w-[350px] mb-4" src="/images/theme/no_identityproviders.svg" />
    <button class="primary-button button-large" (click)="onCreateIdentityProvider()">
        <mat-icon>add</mat-icon>
        {{ 'v2.company.edit.identityproviders.none.found.configure' | translate }}
    </button>
</div>
