<form
    *ngIf="formGroup && !!company"
    [formGroup]="formGroup"
    (ngSubmit)="onSaveConfiguration()"
    novalidate
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true">
    <label for="azuread-name" class="small-input-label" data-name="azuread-name">{{
        'v2.company.edit.identityproviders.azuread.input.name' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="azuread-name"
        name="azuread-name"
        autocomplete="off"
        cdkFocusInitial
        required
        formControlName="name" />
    <p class="small-input-error" *ngIf="this.formGroup.get('name').touched && this.formGroup.get('name').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.name-required' | translate }}
    </p>

    <label for="azuread-displayname" class="small-input-label" data-name="azuread-displayname">{{
        'v2.company.edit.identityproviders.azuread.input.displayName' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="azuread-displayname"
        name="azuread-displayname"
        autocomplete="off"
        cdkFocusInitial
        required
        formControlName="displayName" />
    <p class="small-input-error" *ngIf="this.formGroup.get('displayName').touched && this.formGroup.get('displayName').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.displayName-required' | translate }}
    </p>

    <label for="azuread-tenantId" class="small-input-label" data-name="azuread-tenantId">{{
        'v2.company.edit.identityproviders.azuread.input.tenantId' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="azuread-tenantId"
        name="azuread-tenantId"
        autocomplete="off"
        required
        formControlName="tenantId" />
    <p class="small-input-error" *ngIf="this.formGroup.get('tenantId').touched && this.formGroup.get('tenantId').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.tenantId-required' | translate }}
    </p>

    <label for="azuread-clientid" class="small-input-label" data-name="azuread-clientid">{{
        'v2.company.edit.identityproviders.azuread.input.clientid' | translate
    }}</label>
    <input
        class="small-input"
        type="text"
        id="azuread-clientid"
        name="azuread-clientid"
        autocomplete="off"
        required
        formControlName="clientid" />
    <p class="small-input-error" *ngIf="this.formGroup.get('clientid').touched && this.formGroup.get('clientid').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.clientid-required' | translate }}
    </p>

    <label for="azuread-clientsecret" class="small-input-label" data-name="azuread-clientsecret">{{
        'v2.company.edit.identityproviders.azuread.input.clientsecret' | translate
    }}</label>
    <input
        class="small-input"
        type="password"
        id="azuread-clientsecret"
        name="azuread-clientsecret"
        autocomplete="off"
        required
        formControlName="clientsecret" />
    <p class="small-input-error" *ngIf="this.formGroup.get('clientsecret').touched && this.formGroup.get('clientsecret').invalid">
        {{ 'v2.company.edit.identityproviders.azuread.input.clientsecret-required' | translate }}
    </p>

    <mat-slide-toggle color="primary" formControlName="forceIdp">{{
        'v2.company.edit.identityproviders.azuread.input.forceidp' | translate
    }}</mat-slide-toggle>
    <hr class="seperator" />
    <h4>{{ 'v2.company.edit.identityproviders.azuread.redirecturls' | translate }}</h4>
    <ul class="url-list">
        <li>
            https://login.co-dex.eu/auth/realms/dev-{{ company.id }}/broker/{{ formGroup.get('name').value }}/endpoint
            <button
                type="button"
                mat-icon-button
                [matTooltip]="'general.copy' | translate"
                [cdkCopyToClipboard]="
                    'https://login.co-dex.eu/auth/realms/dev-' + company.id + '/broker/' + formGroup.get('name').value + '/endpoint'
                ">
                <mat-icon>content_copy</mat-icon>
            </button>
        </li>
        <li>
            https://login.co-dex.eu/auth/realms/test-{{ company.id }}/broker/{{ formGroup.get('name').value }}/endpoint
            <button
                type="button"
                mat-icon-button
                [matTooltip]="'general.copy' | translate"
                [cdkCopyToClipboard]="
                    'https://login.co-dex.eu/auth/realms/test-' + company.id + '/broker/' + formGroup.get('name').value + '/endpoint'
                ">
                <mat-icon>content_copy</mat-icon>
            </button>
        </li>
        <li>
            https://login.co-dex.eu/auth/realms/accept-{{ company.id }}/broker/{{ formGroup.get('name').value }}/endpoint
            <button
                type="button"
                mat-icon-button
                [matTooltip]="'general.copy' | translate"
                [cdkCopyToClipboard]="
                    'https://login.co-dex.eu/auth/realms/accept-' + company.id + '/broker/' + formGroup.get('name').value + '/endpoint'
                ">
                <mat-icon>content_copy</mat-icon>
            </button>
        </li>
        <li>
            https://login.co-dex.eu/auth/realms/{{ company.id }}/broker/{{ formGroup.get('name').value }}/endpoint
            <button
                type="button"
                mat-icon-button
                [matTooltip]="'general.copy' | translate"
                [cdkCopyToClipboard]="
                    'https://login.co-dex.eu/auth/realms/' + company.id + '/broker/' + formGroup.get('name').value + '/endpoint'
                ">
                <mat-icon>content_copy</mat-icon>
            </button>
        </li>
    </ul>

    <div class="flex gap-1 justify-end">
        <button data-name="save" class="h-9" [disabled]="!formGroup.valid" type="submit" color="primary" mat-button mat-raised-button>
            <mat-icon>save</mat-icon>
            {{ 'general.save' | translate }}
        </button>
        <button class="h-9" data-name="cancel" (click)="this.onCancel()" type="button" mat-button mat-raised-button>
            <mat-icon>cancel</mat-icon>
            {{ 'general.cancel' | translate }}
        </button>
    </div>
</form>
