<form [formGroup]="formGroup" novalidate cdkTrapFocus *ngIf="formGroup && company" [cdkTrapFocusAutoCapture]="true">
    <div class="flex gap-1">
        <div class="flex-col flex-auto">
            <h1 class="grow !text-xl pt-1 !mb-2">{{ 'v2.company.edit.authentication.pages' | translate }}</h1>
            <label class="small-input-label">{{ 'v2.company.edit.authentication.application.description' | translate }}</label>
            <app-application-picker
                [label]="'v2.company.edit.authentication.application'"
                [selectedApplicationId]="company.loginApplicationId"
                (applicationChanged)="onApplicationChanged($event)"></app-application-picker>

            <ng-container *ngIf="!!company.loginApplicationId">
                <label class="small-input-label">{{ 'v2.company.edit.authentication.login.page.description' | translate }}</label>
                <app-template-picker
                    [label]="'v2.company.edit.authentication.login.page' | translate"
                    [value]="company.loginTemplateId"
                    [applicationId]="company.loginApplicationId"
                    [addQuotesToValue]="false"
                    (valueUpdated)="onLoginTemplateChanged($event)"
                    (openTab)="onOpenTemplate($event)"></app-template-picker>
                <hr class="seperator" />
                <label class="small-input-label">{{
                    'v2.company.edit.authentication.default.redirect.page.description' | translate
                }}</label>
                <app-template-picker
                    [label]="'v2.company.edit.authentication.default.redirect.page' | translate"
                    [value]="company.loginRedirectTemplateId"
                    [applicationId]="company.loginApplicationId"
                    [addQuotesToValue]="false"
                    (valueUpdated)="onLoginRedirectTemplateChanged($event)"
                    (openTab)="onOpenTemplate($event)"></app-template-picker>
                <hr class="seperator" />
                <label class="small-input-label">{{ 'v2.company.edit.authentication.otp.page.description' | translate }}</label>
                <app-template-picker
                    [label]="'v2.company.edit.authentication.otp.page' | translate"
                    [value]="company.loginOtpTemplateId"
                    [applicationId]="company.loginApplicationId"
                    [addQuotesToValue]="false"
                    (valueUpdated)="onLoginOtpTemplateChanged($event)"
                    (openTab)="onOpenTemplate($event)"></app-template-picker>
                <hr class="seperator" />
                <label class="small-input-label">{{ 'v2.company.edit.authentication.otp.config.page.description' | translate }}</label>
                <app-template-picker
                    [label]="'v2.company.edit.authentication.otp.config.page' | translate"
                    [value]="company.loginConfigOtpTemplateId"
                    [applicationId]="company.loginApplicationId"
                    [addQuotesToValue]="false"
                    (valueUpdated)="onLoginOtpConfigTemplateChanged($event)"
                    (openTab)="onOpenTemplate($event)"></app-template-picker>
                <hr class="seperator" />
                <label class="small-input-label">{{ 'v2.company.edit.authentication.reset.password.page.description' | translate }}</label>
                <app-template-picker
                    [label]="'v2.company.edit.authentication.reset.password.page' | translate"
                    [value]="company.loginResetPasswordTemplateId"
                    [applicationId]="company.loginApplicationId"
                    [addQuotesToValue]="false"
                    (valueUpdated)="onLoginResetPasswordTemplateChanged($event)"
                    (openTab)="onOpenTemplate($event)"></app-template-picker>

                <div
                    class="flex mb-1 mt-1 gap-1"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="loginVerifyEmailPageInformationPopover"
                    [appendTo]="'body'"
                    [boundariesElement]="'body'"
                    [preventOverflow]="false"
                    [positionFixed]="true"
                    [applyClass]="'!max-w-[250px]'"
                    [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                    <label class="small-input-label">{{ 'v2.company.edit.authentication.verify.email.page' | translate }}</label>
                    <mat-icon class="help-icon" color="primary">help</mat-icon>
                </div>
                <float-ui-content #loginVerifyEmailPageInformationPopover>
                    <div class="popover">{{ 'v2.company.edit.authentication.verify.email.page.description' | translate }}</div>
                </float-ui-content>
                <app-template-picker
                    [value]="company.loginVerifyEmailTemplateId"
                    [applicationId]="company.loginApplicationId"
                    [addQuotesToValue]="false"
                    (valueUpdated)="onLoginVerifyEmailTemplateChanged($event)"
                    (openTab)="onOpenTemplate($event)"></app-template-picker>

                <div
                    class="flex mb-1 mt-1 gap-1"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="loginPageExpiredInformationPopover"
                    [appendTo]="'body'"
                    [boundariesElement]="'body'"
                    [preventOverflow]="false"
                    [positionFixed]="true"
                    [applyClass]="'!max-w-[250px]'"
                    [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                    <label class="small-input-label">{{ 'v2.company.edit.authentication.expired.page' | translate }}</label>
                    <mat-icon class="help-icon" color="primary">help</mat-icon>
                </div>
                <float-ui-content #loginPageExpiredInformationPopover>
                    <div class="popover">{{ 'v2.company.edit.authentication.expired.page.description' | translate }}</div>
                </float-ui-content>
                <app-template-picker
                    [value]="company.loginPageExpiredTemplateId"
                    [applicationId]="company.loginApplicationId"
                    [addQuotesToValue]="false"
                    (valueUpdated)="onLoginExpiredTemplateChanged($event)"
                    (openTab)="onOpenTemplate($event)"></app-template-picker>

                <div
                    class="flex mb-1 mt-1 gap-1"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="loginUpdatePasswordInformationPopover"
                    [appendTo]="'body'"
                    [boundariesElement]="'body'"
                    [preventOverflow]="false"
                    [positionFixed]="true"
                    [applyClass]="'!max-w-[250px]'"
                    [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                    <label class="small-input-label">{{ 'v2.company.edit.authentication.update.password.page' | translate }}</label>
                    <mat-icon class="help-icon" color="primary">help</mat-icon>
                </div>
                <float-ui-content #loginUpdatePasswordInformationPopover>
                    <div class="popover">{{ 'v2.company.edit.authentication.update.password.page.description' | translate }}</div>
                </float-ui-content>
                <app-template-picker
                    [value]="company.loginUpdatePasswordTemplateId"
                    [applicationId]="company.loginApplicationId"
                    [addQuotesToValue]="false"
                    (valueUpdated)="onLoginUpdatePasswordTemplateChanged($event)"
                    (openTab)="onOpenTemplate($event)"></app-template-picker>

                <div
                    class="flex mb-1 mt-1 gap-1"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="emailValidationNeededInformationPopover"
                    [appendTo]="'body'"
                    [boundariesElement]="'body'"
                    [preventOverflow]="false"
                    [positionFixed]="true"
                    [applyClass]="'!max-w-[250px]'"
                    [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                    <h4 class="!text-sm !font-bold !mb-1 !mt-1 !w-full flex items-center gap-1">
                        {{ 'v2.company.edit.authentication.on.validate.needed.email' | translate }}
                        <svg class="icon icon-button" aria-hidden="true" focusable="false">
                            <use href="#help" class="ui-element"></use>
                        </svg>
                    </h4>
                </div>
                <float-ui-content #emailValidationNeededInformationPopover>
                    <div class="popover">
                        <div class="popover">{{ 'v2.company.edit.authentication.on.validate.needed.email.description' | translate }}</div>
                    </div>
                </float-ui-content>
                <codex-action-picker
                    [referenceId]="company.loginApplicationId"
                    [subReferenceId]="company.id"
                    [applicationId]="company.loginApplicationId"
                    (openTab)="onOpenAction($event)"
                    type="ON_VALIDATE_EMAIL_NEEDED"></codex-action-picker>

                <div
                    class="flex mb-1 mt-1 gap-1"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="requestResetPasswordInformationPopover"
                    [appendTo]="'body'"
                    [boundariesElement]="'body'"
                    [preventOverflow]="false"
                    [positionFixed]="true"
                    [applyClass]="'!max-w-[250px]'"
                    [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                    <h4 class="!text-sm !font-bold !mb-1 !mt-1 !w-full flex items-center gap-1">
                        {{ 'v2.company.edit.authentication.on.request.reset.password' | translate }}
                        <svg class="icon icon-button" aria-hidden="true" focusable="false">
                            <use href="#help" class="ui-element"></use>
                        </svg>
                    </h4>
                </div>
                <float-ui-content #requestResetPasswordInformationPopover>
                    <div class="popover">
                        <div class="popover">{{ 'v2.company.edit.authentication.on.request.reset.password.description' | translate }}</div>
                    </div>
                </float-ui-content>
                <codex-action-picker
                    [referenceId]="company.loginApplicationId"
                    [subReferenceId]="company.id"
                    [applicationId]="company.loginApplicationId"
                    (openTab)="onOpenAction($event)"
                    type="ON_REQUEST_RESET_PASSWORD"></codex-action-picker>

                <div
                    class="flex mb-1 mt-1 gap-1"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="updateEmailInformationPopover"
                    [appendTo]="'body'"
                    [boundariesElement]="'body'"
                    [preventOverflow]="false"
                    [positionFixed]="true"
                    [applyClass]="'!max-w-[250px]'"
                    [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                    <h4 class="!text-sm !font-bold !mb-1 !mt-1 !w-full flex items-center gap-1">
                        {{ 'v2.company.edit.authentication.on.update.email' | translate }}
                        <svg class="icon icon-button" aria-hidden="true" focusable="false">
                            <use href="#help" class="ui-element"></use>
                        </svg>
                    </h4>
                </div>
                <float-ui-content #updateEmailInformationPopover>
                    <div class="popover">
                        <div class="popover">{{ 'v2.company.edit.authentication.on.update.email.description' | translate }}</div>
                    </div>
                </float-ui-content>
                <codex-action-picker
                    [referenceId]="company.loginApplicationId"
                    [subReferenceId]="company.id"
                    [applicationId]="company.loginApplicationId"
                    (openTab)="onOpenAction($event)"
                    type="ON_UPDATE_EMAIL"></codex-action-picker>

                <div
                    class="flex mb-1 mt-1 gap-1"
                    [showTrigger]="NgxFloatUiTriggers.hover"
                    [floatUi]="requireUpdatePasswordInformationPopover"
                    [appendTo]="'body'"
                    [boundariesElement]="'body'"
                    [preventOverflow]="false"
                    [positionFixed]="true"
                    [applyClass]="'!max-w-[250px]'"
                    [placement]="NgxFloatUiPlacements.BOTTOMSTART">
                    <h4 class="!text-sm !font-bold !mb-1 !mt-1 !w-full flex items-center gap-1">
                        {{ 'v2.company.edit.authentication.on.require.update.password' | translate }}
                        <svg class="icon icon-button" aria-hidden="true" focusable="false">
                            <use href="#help" class="ui-element"></use>
                        </svg>
                    </h4>
                </div>
                <float-ui-content #requireUpdatePasswordInformationPopover>
                    <div class="popover">
                        <div class="popover">{{ 'v2.company.edit.authentication.on.require.update.password.description' | translate }}</div>
                    </div>
                </float-ui-content>
                <codex-action-picker
                    [referenceId]="company.loginApplicationId"
                    [subReferenceId]="company.id"
                    [applicationId]="company.loginApplicationId"
                    (openTab)="onOpenAction($event)"
                    type="ON_REQUIRE_UPDATE_PASSWORD"></codex-action-picker>
            </ng-container>
            <hr class="seperator" />
            <h1 class="grow !text-xl pt-1 !m-0">{{ 'v2.company.edit.authentication.identityproviders' | translate }}</h1>
            <codex-company-edit-authentication-identityproviders
                [company]="company"
                [identityProviders]="identityProviders"
                (createIdentityProvider)="createCompanyIdentityProvider.emit($event)"
                (updateIdentityProvider)="updateCompanyIdentityProvider.emit($event)"
                (deleteIdentityProvider)="deleteCompanyIdentityProvider.emit($event)">
            </codex-company-edit-authentication-identityproviders>
            <div class="text-left flex gap-1 justify-end">
                <button class="primary-button button-large" (click)="onUpdateCompany()">
                    <mat-icon>save</mat-icon>
                    {{ 'general.save' | translate }}
                </button>
                <button class="secondary-button button-large" [mat-dialog-close]="true">
                    <mat-icon>cancel</mat-icon>
                    {{ 'general.cancel' | translate }}
                </button>
            </div>
        </div>
    </div>
</form>
